<template>
  <v-container fluid>
    <!-- editor -->
    <editor :id="id" :initial="dataItem" :activeRoute="activeRoute" @data="save"></editor>
    <!-- end -->
  </v-container>
</template>

<script>
import Editor from "../_components/outlet-editor.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  data: () => ({
    dataItem: {},
    activeRoute: {},
    id: null,
    itemsData: [],
    editMode: false,
  }),
  watch: {
    "$route.params.id": {
      handler: "setId",
      immediate: true,
    },
  },
  methods: {
    setId(val) {
      this.id = val;
      const self = this;
      this.$store
        .dispatch("get", `/routes/${val}`)
        .then((res) => {
          self.activeRoute = res;
        })
        .catch((err) => {
          console.log(err, "error");
          // this.$refs.snackbar.show(err, "red");
        });
    },
    save(data) {
      const self = this;
      data.route_id = self.id;
      const url = "/route_outlets";
      this.$store
        .dispatch("post", { url, data })
        .then(() => {
          self.$router.push(`/routes/${self.id}`);
        })
        .catch((err) => {
          console.log(err, "err");
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {},
};
</script>